// VARIABLES DEVARIA
/* variables.module.scss */

:root {
  --color-1: #25cbd3;
  --color-1-opacity-5: #25cbd380;
  --color-2: #5e49ff;
  --color-2-opacity-5: #5e49ff80;
  --color-3: #3BD42D;
  --color-3-opacity-5: #3BD42D80;
  --color-3-opacity-35: #3bd42d59;
  --color-4: #F0F5FF;
  --color-4-opacity-25: #F0F5FF40;
  --color-4-opacity-1: #f0f5ff1a;
  --color-5: #9CF27F;
  --color-5-opacity-5: #9cf27f80;
  --color-6: #A7FAE9;
  --color-7: #DCDCDC;
  --color-8: #0A0A0A;
  --color-9: #d72912;

  --background-color-dark-primary: #1c0c3f;
  --background-color-light-primary:#ffffff;
  --detail-color-light-1: #B0A4FF;
  --detail-color-light-2: #9CF27F;
  --detail-color-light-3: #A191FF;
  --background-login-detail: #1c0c3f;

  --progress-color-1: #3BD42D;
  --progress-color-2: #32a829;
  --progress-color-3: #2a7a25;
  --progress-color-4: #204d1f;
  --progress-color-5: #2d3531;
  --progress-color-6: #3f3f3f;

  // New Colors 2023
  --background-tertiary: #0A0A0A;
  --background-tertiary-light: #1a1a1a;

  --devaria-secondary-color: #A7FAE9;
  --devaria-border-color: #2b2b2b;
  --devaria-skeleton-color: #333333;
  --transparencia-1: rgba(255, 255, 255, 0.1);
}

$primary-color-1:#25cbd3;
$primary-color-2:#5e49ff;
$primary-color-3:#3BD42D;
$secondary-color-1 :#9CF27F;
$detail-color-light-1:#B0A4FF;
$detail-color-light-2:#9CF27F;
$detail-color-light-3:#A191FF;
$detail-color-light-4:#433376;
$detail-color-light-5: #F2F2F2;
$detail-color-light-6: #d5edd2;
$text-color-dark: #F0F5FF;
$text-color-light: #0A0A0A;

$primary-color-mais1code:#FFA252;
$secondary-color-mais1code:#7050F3;

$footer-color-primary: #F2F2F2;
$footer-color-secondary: #B0A4FF;
$footer-color-tertiary: #25cbd3;
$footer-color-quartiary: #3BD42D;
$footer-color-quintiary: #5e49ff;

$background-modal-tela-assistir: #180a35;
$background-tooltip: #180A35;

$dot-slider-secondary: #A7FAE9;

$backgound-status-trasactions-primary:#d8b1b13b;
$backgound-status-trasactions-secondary:#6c58ff3b;
$backgound-status-trasactions-tertiary:#f2c84c12;
$status-trasactions-primary:#d42d2d;
$status-trasactions-secondary:#6c58ff;

$background-color-dark-primary:#1c0c3f;
$background-color-dark-secondary:#24124B;
$background-color-dark-tertiary:#110629;
$background-color-dark-quartiary: #180A35;

$background-modal-suggestions: #000000d6;

$background-color-light-primary:#ffffff;
$background-color-light-secondary:#fafafa;
$background-color-light-tertiary:#f0f0f0;

$background-dark-submenu:#220e4394;
$background-light-submenu:#efefef;
$disable-button:#333;

$background-color-badges: #1c0c3f4d;
$background-color-progressbar-light: #e9e7e7;
$background-box-light: #c0baca;

$progress-bar: #3bd42d80;
$background-progress-bar-perfil: #d5edd2;
$separator-modules-page-class-video: #9cf27f;

$list-inativo-light: #d7291226;
$list-inativo: #d72912;

$skeleton-color-light: #e1e1e1;
$skeleton-highlight-color: #291556;
$skeleton-highlight-color-light: #d0d0d0;

$title-color-devstars: #5c5491;

$button-color-aulas-painel: #B0A4FF;

$color-default: #DCDCDC;

$tootle-admin: #3f51b5;

// Meus pedidos
$payment-color: #3BD42D;
$disapproved-color: #EB5757;
$await-payment-color: #F2C94C;

// Dev Stars heatmap colors
$heatmap-color-primary: rgba(59, 212, 45, 1);
$heatmap-color-secondary: rgba(59, 212, 45, 0.75);
$heatmap-color-tertiary: rgba(59, 212, 45, 0.5);
$heatmap-color-quartiary: rgba(59, 212, 45, 0.25);



// New Colors 2023
$background-tertiary: #0A0A0A;
$background-tertiary-light: #1a1a1a;

$devaria-secondary-color: #A7FAE9;
$devaria-border-color: #2b2b2b;
$devaria-skeleton-color: #333333;

$transparencia-1:rgba(255, 255, 255, 0.1);

:export {
  primaryColor1: $primary-color-1;
  primaryColor2: $primary-color-2;
  primaryColor3: $primary-color-3;
  secondaryColor1: $secondary-color-1;
  detailColorLight1: $detail-color-light-1;
  detailColorLight2: $detail-color-light-2;
  detailColorLight3: $detail-color-light-3;
  detailColorLight4: $detail-color-light-4;
  detailColorLight5: $detail-color-light-5;
  detailColorLight6: $detail-color-light-6;
  colorTextDark: $text-color-dark;
  colorTextLight: $text-color-light;
  colorSeparatorModulesPageClass: $separator-modules-page-class-video;
  dotSliderSecondary: $dot-slider-secondary;

  primaryColorMais1code: $primary-color-mais1code;
  secondaryColorMais1code: $secondary-color-mais1code;

  backgoundStatusTrasactionsPrimary:$backgound-status-trasactions-primary;
  backgoundStatusTrasactionsSecondary:$backgound-status-trasactions-secondary;
  backgoundStatusTrasactionsTertiary: $backgound-status-trasactions-tertiary;
  statusTrasactionsPrimary:$status-trasactions-primary;
  statusTrasactionsSecondary:$status-trasactions-secondary;

  footerColorPrimary: $footer-color-primary;
  footerColorSecondary: $footer-color-secondary;
  footerColorTertiary: $footer-color-tertiary;
  footerColorQuartiary: $footer-color-quartiary;
  footerColorQuintiary: $footer-color-quintiary;

  backgroundColorDarkPrimary: $background-color-dark-primary;
  backgroundColorDarkSecondary: $background-color-dark-secondary;
  backgroundColorDarkTertiary: $background-color-dark-tertiary;
  backgroundColorDarkQuartiary: $background-color-dark-quartiary;

  backgroundModalTelaAssistir: $background-modal-tela-assistir;
  backgroundTooltip: $background-tooltip;

  backgroundModalSuggestions: $background-modal-suggestions;

  backgroundColorLightPrimary: $background-color-light-primary;
  backgroundColorLightSecondary: $background-color-light-secondary;
  backgroundColorLightTertiary: $background-color-light-tertiary;

  submenuBackgroundDark: $background-dark-submenu;
  submenuBackgroundLight: $background-light-submenu;
  disableButton: $disable-button;

  colorDefault: $color-default;

  hrPrimary: $primary-color-2;
  hrSecondary: $footer-color-quartiary;

  backgroundColorBadges: $background-color-badges;
  backgroundColorProgressbarLight: $background-color-progressbar-light;
  backgroundBoxLight: $background-box-light;

  progressBar: $progress-bar;
  backgroundProgressBarPerfil: $background-progress-bar-perfil;

  listInativoLight: $list-inativo-light;
  listInativo: $list-inativo;

  skeletonColorLight: $skeleton-color-light;
  skeletonHighlightColor: $skeleton-highlight-color;
  skeletonHighlightColorLight: $skeleton-highlight-color-light;

  titleColorDevstars: $title-color-devstars;

  buttonColorAulasPainel: $button-color-aulas-painel;

  toogleAdmin: $tootle-admin;

  paymentColor: $payment-color;
  disapprovedColor: $disapproved-color;
  awaitPaymentColor: $await-payment-color;

  heatamapColorPrimary: $heatmap-color-primary;
  heatamapColorSecondary: $heatmap-color-secondary;
  heatamapColorTertiary: $heatmap-color-tertiary;
  heatamapColorQuartiary: $heatmap-color-quartiary;


  backgroundTertiary: $background-tertiary;
  backgroundTertiaryLight: $background-tertiary-light;

  devariaSecondaryColor: $devaria-secondary-color;
  devariaBorderColor: $devaria-border-color;
  devariaSkeletonColor: $devaria-skeleton-color;

  transparencia1: $transparencia-1;
}
