// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.206/distr/fira_code.css);

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

body {
  display: block;
  background: #0a0a0a !important;
  font-family: "Biennale", sans-serif;

  [data-radix-popper-content-wrapper]{
    position: absolute;
    z-index: 15 !important;
  }

  .content {
    width: 110px;
    border: 1px solid var(--color-4);
    border-radius: 12px;
    position: relative;
    top: 12px;
    text-align: center;
    background: var(--color-8);
    outline: 0;
    padding: 6px;

    @media (min-width: 769px) {
      width: 180px;
      padding: 8px;
    }


      &:after,
      &:before {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid var(--color-8);
        /* bottom: 60px; */
        top: -7px;
        content: "";
        left: 29px;
        position: absolute;

        @media (min-width: 769px) {
          left: 44.5%;
        }
      }

      .arrow-tooltipe {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid var(--color-4);
        top: -9px;
        content: "";
        left: 27px;
        position: absolute;

        @media (min-width: 769px) {
          left: 44%;
        }
      }

  }

  .content span {
    text-align: center;
    color: var(--color-4);
  }
}

#linkDiscord {
  font-size: 14px;
  font-family: 'Biennale Regular';
  background: var(--color-5);
  padding: 10px 15px;
  border-radius: 8px;
}

.editor {
  max-height: 400px;
}

.my-modal .modal-content {
  background-color: #0a0a0a;
  border: 1px solid #0a0a0a;
}

// .customizable-text-h1 {

// }

.customizable-text-h2 {
  font-family: "Biennale Black";
  font-style: normal;
  font-weight: 900;

  font-size: 3.692rem;
  line-height: 56px;

  color: #f0f5ff;
  margin: 0;

  @media (min-width: 1728px) {
    font-size: 2.7778vw;
    line-height: 3.24vw;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 32px;
  }
}

//OBS -> O componente IntroVideo entra em Mobile a partir de 960px
.customizable-text-h2-video {
  font-family: "Biennale Black";
  font-style: normal;
  font-weight: 900;

  color: #f0f5ff;
  margin: 0;

  @media (min-width: 1728px) {
    font-size: 2.7778vw;
    line-height: 3.24vw;
  }

  @media (max-width: 1727px) and (min-width: 1367px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media (max-width: 1366px) {
    font-size: 45px;
    line-height: 53px;
  }

  @media (max-width: 1280px) {
    font-size: 42px;
    line-height: 50px;
  }

  @media (max-width: 1200px) {
    font-size: 40px;
    line-height: 46px;
  }

  @media (max-width: 1024px) {
    font-size: 34px;
    line-height: 40px;
  }

  @media (max-width: 960px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media (max-width: 360px) {
    font-size: 26px;
    line-height: 30px;
  }
}

.customizable-text-h3 {
  font-family: "Biennale SemiBold";
  font-style: normal;
  font-weight: 700;

  font-size: 3.0769rem;
  line-height: 48px;

  color: #f0f5ff;
  margin: 0;

  @media (min-width: 1728px) {
    font-size: 2.3148vw;
    line-height: 2.7778vw;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
}

//OBS -> O componente IntroVideo entra em Mobile a partir de 960px
.customizable-text-h3-video {
  font-family: "Biennale SemiBold";
  font-style: normal;
  font-weight: 700;

  color: #f0f5ff;
  margin: 0;

  @media (min-width: 1728px) {
    font-size: 2.3148vw;
    line-height: 2.7778vw;
  }

  @media (max-width: 1727px) and (min-width: 1367px) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (max-width: 1366px) {
    font-size: 38px;
    line-height: 45px;
  }

  @media (max-width: 1280px) {
    font-size: 35px;
    line-height: 42px;
  }

  @media (max-width: 1200px) {
    font-size: 33px;
    line-height: 40px;
  }

  @media (max-width: 1024px) {
    font-size: 28px;
    line-height: 34px;
  }

  @media (max-width: 960px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.customizable-text-h4 {
  font-family: "Biennale Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 5.555555555555555vw;
  line-height: 3.888888888888889vw;

  color: #f0f5ff;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 80px;
    line-height: 56px;
  }
}

.customizable-text-h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 0.9027777777777778vw;
  line-height: 1.1111111111111112vw;
  letter-spacing: 0.08em;

  text-transform: uppercase;

  color: #f0f5ff;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.customizable-text-p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.384rem;
  line-height: 28px;
  font-family: "Biennale Regular";

  color: #f0f5ff;
  margin: 0;

  li {
    font-style: normal;
    font-weight: 400;
    font-size: 1.5385rem;
    line-height: 32px;
    display: flex;
    gap: 0 16px;

    color: #f0f5ff;
    margin: 0;

    @media (min-width: 1728px) {
      font-size: 1.157vw;
      line-height: 1.62vw;
      gap: 0 8px;
    }

    @media (max-width: 768px) {
      line-height: 28px;
      gap: 0 8px;
    }

    @media (max-width: 768px) and (min-width: 649px) {
      font-size: 2.343vw;
    }

    @media (max-width: 648px) and (min-width: 541px) {
      font-size: 2.777vw;
    }

    @media (max-width: 540px) {
      font-size: 18px;
    }
  }

  ul {
    list-style: none;
    margin-left: -24px;
    padding-right: 23px;

    @media (max-width: 768px) {
      margin-left: -32px;
      padding-right: 0px;
    }
  }

  li::before {
    content: "  \002022";
    font-family: "Arial Black";
    color: #25cbd3;

    @media (max-width: 768px) {
      content: " \002022";
    }
  }

  strong {
    font-weight: 700;
  }

  @media (min-width: 1728px) {
    font-size: 1.0416vw;
    line-height: 1.62vw;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.customizable-text-p-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2307rem;
  line-height: 28px;

  color: #f0f5ff;
  margin: 0;

  strong {
    font-weight: 700;
  }

  @media (min-width: 1728px) {
    font-size: 0.925vw;
    line-height: 1.62vw;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
  }
}

.customizable-text-p-3 {
  font-style: normal;
  font-weight: 400;
  font-size: 1.384rem;
  line-height: 32px;

  color: #f0f5ff;
  margin: 0;

  strong {
    font-weight: 700;
  }

  @media (min-width: 1728px) {
    font-size: 1.041vw;
    line-height: 1.851vw;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
}

//OBS -> O componente IntroVideo entra em Mobile a partir de 960px
.customizable-text-p-video {
  font-style: normal;
  font-weight: 400;
  font-family: "Biennale Regular";

  color: #f0f5ff;
  margin: 0;

  strong {
    font-weight: 700;
  }

  @media (min-width: 1728px) {
    font-size: 1.041vw;
    line-height: 1.851vw;
  }

  @media (max-width: 1727px) and (min-width: 1367px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 1366px) {
    font-size: 17px;
    line-height: 26px;
  }

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 25px;
  }

  @media (max-width: 1200px) {
    font-size: 15px;
    line-height: 23px;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
  }

  @media (max-width: 960px) {
    font-size: 14px;
    line-height: 24px;
  }
}

// PADRONIZAÇÃO PADDINGS
.container-padranizado {
  display: flex;
  flex-direction: column;
  padding: 80px 5.556vw;
  gap: 80px;

  @media (max-width: 768px) {
    gap: 40px;
    padding: 6.67vw 6.67%;
  }

  @media (max-width: 425px) {
    gap: 40px;
    padding: 40px 24px;
  }
}

.customizable-text-painel-h1 {
  font: 2.22222vw "Biennale Black";
  line-height: 1;
  color: var(--color-4);
  margin-bottom: 24px;

  @media (max-width: 769px) {
    font-size: 28px;
    line-height: 32px;
    width: 78%;
  }
}

.MuiPickersDay-daySelected, .MuiPickersDay-daySelected:hover {
  background-color: var(--color-3)!important;
}
// Unidades de px para vw padrão
// 110px => Result = 7.638888888888889vw
// 80px => Result = 5.555555555555555vw
// 72px => Result = 5vw
// 64px => Result = 4.444444444444445vw
// 56px => Result = 3.888888888888889vw
// 48px => Result = 3.3333333333333335vw
// 40px => Result = 2.7777777777777777vw
// 32px => Result = 2.2222222222222223vw
// 28px => Result = 1.9444444444444444vw
// 26px => Result = 1.8055555555555556vw
// 24px => Result = 1.6666666666666667vw
// 20px => Result = 1.3888888888888888vw
// 18px => Result = 1.25vw
// 16px => Result = 1.1111111111111112vw
// 14px => Result = 0.9722222222222222vw
// 13px => Result = 0.9027777777777778vw
// 12px => Result = 0.8333333333333334vw
