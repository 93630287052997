//
// Layout Config
// Here you can set the demo level SASS variables and override the global variables for this demo.
// Note: To override the global variables in the demo level use the variables without "!default". More info:  https://sass-lang.com/documentation/variables
//

@import '../../../../variables.scss';

// State colors
$kt-state-colors: (
    // Metronic states
    brand: (
        base: $background-color-dark-primary,
        inverse: $background-color-light-primary
    ),
    light: (
        base: $background-color-light-primary,
        inverse: #282a3c
    ),
    dark: (
        base: #282a3c,
        inverse: $background-color-light-primary
    ),

    // Bootstrap states
    primary: (
        base: $background-color-dark-primary,
        inverse: $background-color-light-primary
    ),
    success: (
        base: #3DFF9E, //1dc9b7,
        inverse: $background-color-light-primary
    ),
    info: (
        base: #5578eb,
        inverse: $background-color-light-primary
    ),
    warning: (
        base: $primary-color-1, //#ffb822,
        inverse: #111111
    ),
    danger: (
        base: #FF7563,
        inverse: $background-color-light-primary
    ),
    shape: (
        base: #646c9a,
        inverse: $background-color-light-primary
    ),
    optionSelected:(
        base: $background-color-dark-primary,
        inverse: $text-color-dark  //#384c1eb4
    ),
    lateralAdmin:(
        base: $background-color-dark-primary,
        inverse: $primary-color-2
    ),
    colorPortilet:(
        base: $background-color-dark-primary,
        inverse: $background-color-light-primary
    )
);

$kt-state-colors-iniciatives: (
    orange: (
        base: #FFC85C,
        inverse: $background-color-light-primary
    ),
    brown: (
        base: #994834 ,
        inverse: #282a3c
    ),
    blue: (
        base: #5B7EB3,
        inverse: $background-color-light-primary
    ),
    green: (
        base: #6A992C,
        inverse: $background-color-light-primary
    ),
    grey: (
        base: #ccc,
        inverse: $background-color-light-primary
    )
);

// Layout
$kt-aside-default-width: 240px;
$kt-aside-minimize-width: 78px;
$kt-aside-offcanvas-width: 255px;

// Page padding
$kt-page-padding: (
	desktop: 25px,
	mobile: 15px
);

// Page container
$kt-page-container-width: 1380px; //  fixed page width

// Content bg color
$kt-content-bg-color: $background-color-light-primary;
// Aside bg color
$kt-aside-bg-color: $background-color-dark-primary;
