@import '../../../../variables.scss';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  font-size: 14px;

  background: $background-color-dark-primary;
}

.parceiros-content {
  text-align: center;
  background: white;
  padding: 20px 10px;

  .image-parceiros {
    max-width: 900px;
    width: 100%;
  }
}

.tui-full-calendar-layout {
  @media (max-width: 576px) {
    width: 800px;
  }
}

